.footer {
    padding-bottom: 5rem;
    .container {
        justify-content: center;
        padding-block: 5rem;
        overflow: hidden;

        .quote-container {
            position: relative;
            padding-inline: 1rem;
            font-family: 'Kalam', cursive;
            font-size: 1.25rem;
            line-height: 2;

            .note {
                max-width: 100%;

                h2 {
                    color: #000;

                    &::after {
                        border-top: 4px solid #000;
                    }
                }
            }
        
            .sticky {
                background: var(--sticky-note-blue)
            }

            .author {
                font-weight: 600;
            }
        }               
    }
}

@media (min-width: 700px) {
    .footer {
        .container {
            .quote-container {
                .note {
                    max-width: 75vw;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media (min-width: 1000px) {
    .footer {
        .container {
            .quote-container {
                .note {
                    max-width: 35vw;                    
                }
            }
        }
    }
}

@media (min-width: 1600px) {
    .footer {
        .container {
            .quote-container {
                
                .note {
                    max-width: 20vw;
                }
            }
        }
    }
}