.theme-btn {
    
    .toggle {
        
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .checkbox {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0%;
            width: 1.5rem;
            height: 1.5rem;
            z-index: 100;
            cursor: pointer;
        }

        .btn {

            .moon, .sun {
                position: absolute;
                top: 0;
                right: 0%;
                z-index: -1;
                transition: opacity .2s ease-in-out;
            }

            .sun {
                opacity: 0;
            }
        }

        .active {

            .sun {
                opacity: 1;
            }

            .moon {
                opacity: 0;
            }
        }

        
    }
}