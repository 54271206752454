.slider {
    margin-block: 1rem;
    .container {
        flex-direction: column;
        position: relative;

        .title {
            margin-bottom: 1rem;
        }
        .project {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            font-family: 'Kalam', cursive;
            font-size: 1.25rem;
            width: 0;
            height: 0;
            opacity: 0;
            z-index: -1;

            .wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-inline: 2rem;

                .quote-container {
                    position: relative;
                    
                    .note {
                        width: 100%;
                        
                        img {
                            max-height: 30rem;
                            object-fit: cover;
                        }
                        
                    }
                
                    .sticky {
                        background: var(--sticky-note-blue);
                    }
                }

                .features {
                    width: 100%;
                    margin-top: 1rem;
                    padding-block: 1rem;
                    padding-inline: 1.5rem;

                    span {
                        display: block;
                        text-align: left;
                        margin: 0;
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        gap: 1rem;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                    }

                }

                .description {
                    p {
                        padding-inline: 1rem;
                        margin-bottom: 5rem;
                    }

                    .buttons {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        gap: 1rem;
                        margin-block: 1rem;

                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: 'Kalam', cursive;
                            font-size: 1rem;
                            font-weight: 700;
                            width: 100%;
                            color: var(--text-color);
                            background: none;
                            border: none;
                            cursor: pointer;
                        }

                        a {
                            text-align: center;

                            button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: 'Kalam', cursive;
                                font-size: 1rem;
                                font-weight: 700;
                                width: 100%;
                                color: var(--text-color);
                                background: none;
                                border: none;
                                cursor: pointer;
                            }
                        
                            .try-btn {
                                position: relative;
                        
                                svg {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 30rem;
                                    height: 7.5rem;
                                }
                            }
                        }
                    }
                }

            }
        }

        .active {
            width: 100%;
            height: auto;
            opacity: 1;
            z-index: 1;
            // transition: all .5s cubic-bezier(0.23, 1, 0.32, 1);
            animation: fadeIn .3s ease-in-out;

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: .5;
                }
                100% {
                    opacity: 1;
                }
            }
        }

        .slide-btn {
            position: absolute;
            width: 100%;
            top: 50rem;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 2;

            .prev, .next {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 700px) {
    .slider {
        .container {
            .project {
                .wrapper {
                    .quote-container {
                        .note {
                            max-width: 50vw;
                        }
                    }

                    .features {
                        padding-inline: 2rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 1000px) {
    .slider {
        .container {
            .project {
                .wrapper {
                    .quote-container {
                        .note {
                            max-width: 30vw;
                        }
                    }

                    .features {
                        span {
                            text-align: center;
                        }
                       ul {
                        flex-direction: row;
                        justify-content: center
                       }
                    }
                }
            }
        }
    }
}

@media (min-width: 1600px) {
    .slider {
        .container {
            .project {
                .wrapper {
                    .quote-container {
                        .note {
                            max-width: 20vw;
                        }
                    }
                }
            }
        }
    }
}