@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Permanent+Marker&family=Titillium+Web:wght@400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Montserrat:wght@400;500;600;700&family=Permanent+Marker&family=Titillium+Web:wght@400;600;700;900&display=swap');



:root {
    --body-background-color: #fff;
    --body-grid-line-color: #ccc;
    --background-size: 40px 40px;
    --text-color: #000;
    --sticky-note-yellow: #eae672;
    --sticky-note-blue: linear-gradient(180deg,rgba(187, 235, 255, 1) 0%,rgba(187, 235, 255, 1) 12%,rgba(170, 220, 241, 1) 75%,rgba(195, 229, 244, 1) 100%);

    --max-width: 1000px;
}

* {
    box-sizing: border-box;
}

.App {
    min-height: 100vh;
    background-color: var(--body-background-color);
    background-image:
        conic-gradient(at calc(100% - .5px) calc(100% - .5px), var(--body-grid-line-color) 270deg, #0000 0);
    background-size: var(--background-size);
    color: var(--text-color);
    overflow: clip;

    a {
        text-decoration: none;
        color: var(--text-color);
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    p {
        color: var(--text-color)
    }

    .container {
        display: flex;
        align-items: center;
        width: 90%;
        max-width: var(--max-width);
        margin: 0 auto;
        position: relative;
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-block: 1.5rem;

        h1 {
            font-family: 'Kalam', cursive;
            background: linear-gradient(120deg, #D6009A 0%, #9750dd 50%, #D6009A 100%);
            background-origin: padding-box;
            background-size: 200%;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            animation: 3s linear 0s infinite normal forwards running text-color-animation;
        
            @keyframes text-color-animation {
                100% {
                    background-position: -200% center
                }
            }
        }

        h2 {
            position: relative;
            padding: 1rem;
            color: var(--text-color);
            font-family: "Permanent Marker", cursive;

            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 10px;
                border-top: 4px solid var(--text-color);
                border-radius: 60%;
                bottom: 0;
                left: 0;
            }
        }
    }

    .big-padding {
        padding-top: 5vh;
    }

    .quote-container {

        .pin {
            background-color: #aaa;
            display: block;
            height: 32px;
            width: 2px;
            position: absolute;
            left: 50%;
            top: -16px;
            z-index: 1;

            &::before {
                background-color: hsla(0, 0%, 0%, 0.1);
                box-shadow: 0 0 .25em hsla(0, 0%, 0%, .1);
                content: '';
                height: 24px;
                width: 2px;
                left: 0;
                position: absolute;
                top: 8px;

                transform: rotate(57.5deg);
                -moz-transform: rotate(57.5deg);
                -webkit-transform: rotate(57.5deg);
                -o-transform: rotate(57.5deg);
                -ms-transform: rotate(57.5deg);

                transform-origin: 50% 100%;
                -moz-transform-origin: 50% 100%;
                -webkit-transform-origin: 50% 100%;
                -ms-transform-origin: 50% 100%;
                -o-transform-origin: 50% 100%;
            }

            &::after {
                background-color: #A31;
                background-image: radial-gradient(25% 25%, circle, hsla(0, 0%, 100%, .3), hsla(0, 0%, 0%, .3));
                border-radius: 50%;
                box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, .1), inset 3px 3px 3px hsla(0, 0%, 100%, .2), inset -3px -3px 3px hsla(0, 0%, 0%, .2), 23px 20px 3px hsla(0, 0%, 0%, .15);
                content: '';
                height: 12px;
                left: -5px;
                position: absolute;
                top: -10px;
                width: 12px;
            }
        }

        .note {
            color: #000;
            position: relative;
            margin: 0 auto;
            padding: 20px;
            font-family: 'Kalam', cursive;
            font-size: 1.875rem;
            box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.3);

            .author {
                display: block;
                padding-top: 1rem;
                margin: 0;
                text-align: right;
            }
        }

        .sticky {
            -webkit-transform: rotate(2deg);
            -moz-transform: rotate(2deg);
            -o-transform: rotate(2deg);
            -ms-transform: rotate(2deg);
            transform: rotate(2deg);
        }
    }
}

.dark-mode {
    --body-background-color: #23272f;
    --body-grid-line-color: #444;
    --text-color: #fff;
}

@media (min-width: 1000px) {
    .App {
        .big-padding {
            padding-top: 7vh;
        }
    }
}