.contact-me {
    padding-bottom: 4rem;
    .container {
        flex-direction: column;
        justify-content: center;
        .quote-container {
            position: relative;

            .note {
                width: 100%;

                form {
                    .contact-form {
                        .contact-info {
                            display: flex;
                            gap: 1rem;
                            align-items: center;
                            position: relative;

                            
                        }
                        .contacts,
                        textarea {
                            background: none;
                            border: none;
                            border-bottom: 2px solid #000;
                            outline: none;
                            width: 100%;
                            font-family: 'Kalam', cursive;
                            
                            box-sizing: border-box;
                        }

                        textarea {
                            resize: none;

                            &::-webkit-scrollbar {
                                width: 0;
                            }
                        }
                    }
                }
            }

            .sticky {
                background: var(--sticky-note-yellow);
            }

            .author {
                display: flex;
                justify-content: flex-end;
                button {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    border: none;
                    background: none;
                    font-family: 'Kalam', cursive;
                    font-size: 1.5rem;
                    cursor: pointer;
                    color: #000;

                    .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 10px;
            border-top: 4px solid var(--text-color);
            border-radius: 60%;
            bottom: -20%;
            left: 0;
        }
    }
}