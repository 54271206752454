.profile {

    .container {
        flex-direction: column;
        justify-content: center;

        .me {
            display: grid;
            gap: 2rem;
            .photo {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                align-items: center;
                justify-content: center;
                width: 100%;

                .quote-container {
                    position: relative;

                    .note {
                        width: 18.75rem;
                    }
                
                    .sticky {
                        background: var(--sticky-note-yellow);
                    }
                }

                

                .social {
                    display: flex;
                    gap: 2rem;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 1rem;
                }
            }

            .skills {
                display: grid;
                place-items: center;
                gap: 1rem;

                .scroller {

                    ul {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 1rem;
                        margin: 0;
                        padding: 0;
                        padding-block: 1rem;
                        list-style: none;

                        li {
                            padding: 1rem;

                            span {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                font-family: "Permanent Marker", cursive;
                                font-size: 1.25rem;
                            }
                        }
                    }

                }               

            }
        }

        .about-me {

            p {
                font-family: 'Kalam', cursive;
                font-size: 1.25rem;
                padding-inline: 1rem;
                line-height: 2;
            }
        }
    }
}

@media (min-width: 1000px) {
    .profile {
        .container {
            .me {
                display: flex;
                width: 100%;

                .photo {
                    order: 2;
                    flex: 40%;
                }

                .skills {
                    flex: 50%;

                    .scroller {
                        ul {
                            position: relative;
                            width: 24em;
                            height: 24em;
                            // padding: 2.8em;
                            /*2.8em = 2em*1.4 (2em = half the width of a link with img, 1.4 = sqrt(2))*/
                            // border: dashed 1px;
                            border-radius: 50%;
                            margin: 0 auto;

                            li {
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 4em;
                                height: 4em;
                                margin: -2em;

                                &:nth-child(1) {
                                    // animation: spin 2s linear infinite;
                                    transform: translate(12em);
                                }
                                &:nth-child(2) {
                                    // animation: spin 4s linear infinite;
                                    transform: rotate(45deg) translate(12em) rotate(-45deg);
                                }
                                &:nth-child(3) {
                                    // animation: spin 6s linear infinite;
                                    transform: rotate(90deg) translate(12em) rotate(-90deg);
                                }
                                &:nth-child(4) {
                                    // animation: spin 8s linear infinite;
                                    transform: rotate(135deg) translate(12em) rotate(-135deg);
                                }
                                &:nth-child(5) {
                                    // animation: spin 18s linear infinite;
                                    transform: translate(-12em);
                                }
                                &:nth-child(6) {
                                    // animation: spin 10s linear infinite;
                                    transform: rotate(225deg) translate(12em) rotate(-225deg);
                                }
                                &:nth-child(7) {
                                    // animation: spin 12s linear infinite;
                                    transform: rotate(270deg) translate(12em) rotate(-270deg);
                                }
                                &:nth-child(8) {
                                    // animation: spin 16s ease-in-out infinite;
                                    transform: rotate(315deg) translate(12em) rotate(-315deg);
                                }

                                // @keyframes spin {
                                //     from {
                                //         transform: rotate(-1turn) translate(-12) rotate(1turn);
                                //     }
                                //     to {
                                //         transform: rotate(1turn) translate(12em) rotate(-1turn);
                                //     }
                                // }
                            }
                        }
                    }
                }
            }
        }
    }
}