.navbar {
    background-color: var(--body-background-color);
    background-image: conic-gradient(at calc(100% - .5px) calc(100% - .5px), var(--body-grid-line-color) 270deg, #0000 0);
    background-size: var(--background-size);
    color: var(--text-color);

    position: fixed;
    top: 0;
    width: 100vw;
    min-height: 7vh;
    display: flex;
    align-items: center;
    z-index: 999;
    // display: none;

    .container {
        justify-content: space-between;

        .hamburger-btn {
            position: relative;

            #toggle-btn {
                display: block;
                height: 25px;
                width: 25px;
                opacity: 0;
                z-index: 900;
                cursor: pointer;
            }
        
            .btn-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 30px;
        
                .menu-btn {
                    position: absolute;
                    display: block;
                    background-color: var(--text-color);
                    height: 2px;
                    width: 20px;
                    margin-top: -25px;
                    border-radius: 2px;
                    transition: transform 400ms cubic-bezier(0.23, 1, 0.320, 1);
                    z-index: -1;
        
                    &::before {
                        content: '';
                        margin-top: -8px;
                        position: absolute;
                        display: block;
                        background-color: var(--text-color);
                        height: 2px;
                        width: 25px;
                        border-radius: 2px;
                        transition: transform 400ms cubic-bezier(0.23, 1, 0.320, 1);
                    }
        
                    &::after {
                        content: '';
                        margin-top: 8px;
                        position: absolute;
                        display: block;
                        background-color: var(--text-color);
                        height: 2px;
                        width: 25px;
                        border-radius: 2px;
                        transition: transform 400ms cubic-bezier(0.23, 1, 0.320, 1);
                    }
                }
            }
        }

        #toggle-btn:checked+.btn-container .menu-btn::before {
            margin-top: -5px;
            transform: rotate(405deg);
        }

        #toggle-btn:checked+.btn-container .menu-btn {
            background: rgba(255, 255, 255, 0);
        }

        #toggle-btn:checked+.btn-container .menu-btn::after {
            margin-top: -5px;
            transform: rotate(-405deg);
        }

        .nav-list {
            position: absolute;
            list-style-type: none;
            top: 150%;
            left: 50%;
            right: 0;
            transform: translate(-50%) rotate(2deg);
            margin: 0;
            padding: 0;
            z-index: 100;

            ul {
                padding: 0;
                margin: 0;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                cursor: pointer;

                height: 0;
                margin: 0;
                padding: 0;
                transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);

                a {
                    color: #000;
                }
            }
        }

        .open {
            z-index: 100;

            li {
                height: 2.5em;
                padding: .5rem 0;
                transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
            }
        }

        .note {
            width: 18.75rem;
        }

        .sticky {
            background: var(--sticky-note-yellow);
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 10px;
            border-top: 4px solid var(--text-color);
            border-radius: 60%;
            bottom: -65%;
            left: 0;
        }
    }
}

@media (min-width: 700px) {
    .navbar {
        .container {
            &::after {
                bottom: -100%
            }
        }
    }
}

@media (min-width: 1000px) {
    .navbar {
        min-height: 3vh;
        padding-block: 1rem;

        .container {
            &::after {
                bottom: -50%;
            }

            .hamburger-btn {
                #toggle-btn {
                    display: none;
                }

                .btn-container {
                    display: none;
                }
            }
            .clickOutside {
                width: 100%;
                .quote-container {
                    width: 80%;
                    margin: 0 auto;
            
                    .nav-list {
                        position: relative;
                        transform: translate(-50%) rotate(0deg);
            
                        ul {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-family: 'Kalam', cursive;
                            font-size: 1.25rem;
            
            
                            li {
                                height: 2.5em;
                                padding: .5rem 0;
                            }
                        }
            
                    }
                }
            }
            
        }
    }
}